.abaris-nav .navbar.navbarItem {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}

.sticky {
    background: #fff;
    position: relative;
    z-index: 1;
}

.sticky.active {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 11;
    transition: .4s ease-in-out;
    box-shadow: 0px 15px 10px -15px #6e6e6eb8;
}

.navbarArea {
    background-color: #e1e1e1;
}

.allCategoryDrop {
    padding: 7px 10px;
    background: #efefef;
    width: 260px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuCard {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.allCategoryDrop i {
    font-size: 20px;
    cursor: pointer;
}

.bannerContent .title p {
    color: #626262;
}

.categoryList {
    position: absolute;
    top: -350px;
    left: 0;
    z-index: -111;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .4s ease-in-out;
}

.categoryListBody {
    background: #fff;
    padding: 20px;
    border-top: 1px solid #efefef;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.categoryList ul {
    margin: 0;
}

.categoryList ul li a,
.categoryListCard ul li a {
    text-transform: capitalize;
    color: #6e6e6e;
    line-height: 33px;
    display: block;
    transition: .4s ease-in-out;    font-size: 14px;
}

.allCategoryDrop.active .categoryList {
    opacity: 1;
    top: 44px;
    z-index: 111;
    position: absolute;
}

.categoryList ul li a:hover,
.categoryListCard ul ul li a:hover {
    color: #33423d;
}

.categoryListCard h5 {
    text-transform: capitalize;
    color: #333;
}

.categoryListCard ul {
    padding-left: 20px;
    margin: 0;
    list-style: disc;
}
.categoryListCard ul li::marker{
    color: #33423d;
}